import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'backstage',
    title: 'GOSU BOX',
    loadChildren: () => import('./backstage/backstage.routes'),
  },

  {
    path: '**',
    redirectTo: 'backstage',
  },
];
