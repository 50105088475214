import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { Integration } from '@sentry/types';
import 'reflect-metadata';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

const { sentry } = environment;

if (sentry.enable) {
  const integrations: Integration[] = [];

  const browserTracing = sentry.browserTracing;
  if (browserTracing.enable) {
    const tracePropagationTargets = browserTracing.tracePropagationTargets;

    integrations.push(
      new Sentry.BrowserTracing({
        tracePropagationTargets,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    );
  }

  const { dsn, release } = sentry;
  Sentry.init({ dsn, release, integrations, tracesSampleRate: 1.0 });
}

bootstrapApplication(AppComponent, appConfig).catch((err) => {
  console.error(err);
});
