import { provideHttpClient } from '@angular/common/http';
import {
  ApplicationConfig,
  EnvironmentProviders,
  ErrorHandler,
  Provider,
} from '@angular/core';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { CustomErrorHandler } from './backstage/error.handler';
import { materialProviders } from './providers/material';
import { sentryProviders } from './providers/sentry';
import { angularSvgIconConfig } from './providers/svg-icon';

const providers: Array<Provider | EnvironmentProviders> = [
  provideAnimationsAsync(),
  provideRouter(routes),
  provideHttpClient(),
  provideAngularSvgIcon(angularSvgIconConfig),
  { provide: ErrorHandler, useClass: CustomErrorHandler },
  ...materialProviders,
  ...sentryProviders,
  { provide: 'SERVER_URLS', useValue: environment.servers },
  {
    provide: 'LOCAL_STORAGE_KEY',
    useValue: environment.backstage.localStorageKey,
  },
];

export const appConfig: ApplicationConfig = {
  providers,
};
