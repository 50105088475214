import { Provider } from '@angular/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { zhTW } from 'date-fns/locale';

export const materialProviders: Provider[] = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      floatLabel: 'always',
    },
  },
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: { duration: 3000, horizontalPosition: 'center' },
  },
  {
    provide: MAT_DATE_LOCALE,
    useValue: zhTW,
  },
  { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  {
    provide: DateAdapter,
    useClass: DateFnsAdapter,
    deps: [MAT_DATE_LOCALE],
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: {
      parse: {
        dateInput: 'yyyy/MM/dd',
      },
      display: {
        dateInput: 'yyyy/MM/dd',
        monthYearLabel: 'yyyy年 MM月',
        dateA11yLabel: 'yyyy/MM/dd',
        monthYearA11yLabel: 'yyyy年 MM月',
      },
    },
  },
];
