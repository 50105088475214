import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@ay-gosu/ui/dialog/dialog.service';
import { CodeError } from '@ay/util';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';
const { sentry } = environment;

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
  private readonly _sentry = Sentry.createErrorHandler();

  public constructor(
    private readonly _dialogService: DialogService,
    private readonly _router: Router,
  ) {
    super();
  }

  public override handleError(error: CodeError) {
    switch (error.code) {
      case 'EVENT_NOT_FOUND':
        return this._showEventNotFoundDialog();

      default:
        if (sentry.enable) {
          this._sentry.handleError(error);
        } else {
          console.error(error);
        }
    }
  }

  private _showEventNotFoundDialog() {
    this._dialogService.failure('無法載入活動', {
      messages: '找不到指定的活動',
      buttons: [
        {
          type: 'flat',
          text: '回到活動總覽',
          color: 'warn',
          click: async (dialogRef) => {
            await this._router.navigateByUrl('/backstage/event/all');
            dialogRef.close();
          },
        },
      ],
      disableClose: true,
    });
  }
}
