import { sentryRelease } from './build-args';

export const environment = {
  servers: [location.origin],
  sentry: {
    enable: true,
    dsn: 'https://e905e28b0802ec276c691c6c15565315@sentry.anyong.com.tw/9',
    release: sentryRelease,
    browserTracing: {
      enable: true,
      tracePropagationTargets: [
        /^https:\/\/d-www\.gosu]\.bar/,
        /^https:\/\/www\.gosu]\.bar/,
      ],
    },
  },
  backstage: {
    localStorageKey: 'backstage',
  },
};
